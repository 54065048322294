//NOT EDITABLE!!!
import Hero from "../../components/Hero";
import Footer from "../../components/Footer";

// EDITABLE
import background from "../../assets/img/background.png"; //Background
import overlay from "../../assets/img/overlay.png"; //overlay


function pageLayout(props) {
  //EDITABLE
  const deskripsi = "Selamat datang kembali di";
  const title1 = "Madrasah Sumbar";
  const title2 = "";
  const slogan =
    "Informasi Penerimaan Peserta Didik Baru (PPDB) Madrasah Sumatera Barat Silahkan kunjungi";
  const copyright =
    "2021 Madrasah Sumatera Barat Blended Learning. - Didukung oleh";

  return (
    <div>
      <Hero
        background={background}
        backgroundMobile={background}
        deskripsi={deskripsi}
        title1={title1}
        title2={title2}
        slogan={slogan}
        overlay={overlay}
        appName={props.appName}
      >
        {props.children}
      </Hero>
      <Footer copyright={copyright} />
    </div>
  );
}

export default pageLayout;
